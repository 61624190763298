
import { computed, defineComponent, PropType } from 'vue';
import RiskFlag from '@/domain/enums/RiskFlag';
import convertRiskFlagToDescription from '@/functions/enum';

export default defineComponent({
    name: 'risk-flag',
    props: {
        flagColor: {
            type: Number as PropType<RiskFlag>,
            default: () => RiskFlag.NoFlag,
        },
    },
    setup(props) {
        const flagColorName = computed(() => {
            const riskFlagEnumStr = RiskFlag[props.flagColor];

            return riskFlagEnumStr.toLowerCase();
        });

        const flagHelpText = computed(() => convertRiskFlagToDescription(props.flagColor));

        return {
            flagColorName,
            flagHelpText,
        };
    },
});
