import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c1fa602e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-placeholder" }
const _hoisted_2 = { class: "image-not-found-container" }
const _hoisted_3 = { style: {"margin-top":"-5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_faicon, {
        icon: "image",
        style: _normalizeStyle({fontSize: _ctx.size})
      }, null, 8, ["style"]),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.noImage')), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.getTitleCaseTranslation('core.common.available')), 1)
    ])
  ]))
}