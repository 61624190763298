
import { defineComponent, PropType } from 'vue';
import RiskFlag from '@/components/RiskFlag.vue';
import RiskFlagEnum from '@/domain/enums/RiskFlag';
import { getTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'inventory-risk-icons',
    components: {
        RiskFlag,
    },
    props: {
        hasNegativeFinishedGoodInventory: Boolean,
        risk: {
            type: Number as PropType<RiskFlagEnum>,
            default: () => undefined,
        },
    },
    setup() {
        return {
            getTranslation,
        };
    },
});
