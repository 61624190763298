

import { defineComponent } from 'vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default defineComponent({
    name: 'image-not-found',
    props: {
        size: { type: String, default: '26px' },
    },
    setup() {
        return {
            getTitleCaseTranslation,
        };
    },
});

