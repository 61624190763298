import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_faicon = _resolveComponent("faicon")!

  return (_ctx.flagColorName !== 'noflag')
    ? (_openBlock(), _createBlock(_component_faicon, {
        key: 0,
        icon: "flag",
        style: _normalizeStyle({ color: _ctx.flagColorName }),
        title: _ctx.flagHelpText
      }, null, 8, ["style", "title"]))
    : _createCommentVNode("", true)
}