import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cba67c40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "risk-icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_risk_flag = _resolveComponent("risk-flag")!
  const _component_faicon = _resolveComponent("faicon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_risk_flag, { "flag-color": _ctx.risk }, null, 8, ["flag-color"]),
    (_ctx.hasNegativeFinishedGoodInventory)
      ? (_openBlock(), _createBlock(_component_faicon, {
          key: 0,
          class: "negative-finished-good-inventory-warning",
          icon: "exclamation",
          title: _ctx.getTranslation('core.common.warningNegativeFinishedGoodInventory')
        }, null, 8, ["title"]))
      : _createCommentVNode("", true)
  ]))
}