import RiskFlag from '@/domain/enums/RiskFlag';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default function convertRiskFlagToDescription(riskFlag: RiskFlag) : string {
    switch (riskFlag) {
    case RiskFlag.Red:
        return getTitleCaseTranslation('core.common.criticalShortage');
    case RiskFlag.Orange:
        return getTitleCaseTranslation('core.common.potentialShortage');
    case RiskFlag.Black:
        return getTranslation('core.common.excessOnHand');
    case RiskFlag.NoFlag:
        return getTranslation('core.common.withinAcceptableRange');
    default:
        return '';
    }
}
