
import {
    defineComponent, reactive, PropType, computed,
} from 'vue';
import ImageNotFound from '@/components/ImageNotFound.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';

type State = {
    showLarge: boolean;
    hasError: boolean;
};

type ThumbnailCSS = {
    width: string;
    height: string;
};

export default defineComponent({
    name: 'thumbnail',
    components: {
        BModal,
        ImageNotFound,
    },
    props: {
        imageUrlFull: { type: String, default: undefined },
        imageUrlThumb: { type: String, default: undefined },
        encodedImage: { type: String, default: undefined },
        encodedImageType: { type: String, default: 'image/png' },
        size: { type: String as PropType<'sm' | 'lg'>, default: 'sm' },
        disabled: { type: Boolean, default: false },
        showPlaceholder: { type: Boolean, default: false },
    },
    setup(props) {
        // if this is slow in production, we should use the Image class and show a spinner while it waits to load
        const spinner = require('@/assets/broken-circle-loader.gif');

        const state = reactive<State>({
            showLarge: false,
            hasError: false,
        });

        const encodedImageSrc = computed((): string | null => {
            if (props.encodedImage && props.encodedImageType) {
                return `data:${props.encodedImageType};base64,${props.encodedImage}`;
            }
            return null;
        });

        const preventOpenModal = computed((): boolean => {
            if (props.disabled || state.hasError) return true;
            return !props.imageUrlFull && !encodedImageSrc.value;
        });

        const thumbnailCss = computed(
            (): ThumbnailCSS => {
                if (props.size === 'lg') {
                    return {
                        width: '130px',
                        height: '130px',
                    };
                }
                return {
                    width: '52px',
                    height: '52px',
                };
            },
        );

        function openFullImage() {
            if (!preventOpenModal.value) {
                state.showLarge = true;
            }
        }

        function onImageError() {
            state.hasError = true;
        }

        return {
            state,
            thumbnailCss,
            openFullImage,
            encodedImageSrc,
            preventOpenModal,
            spinner,
            onImageError,
        };
    },
});
