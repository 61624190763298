import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b39e91a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "floortrak-image-thumbnail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_image_not_found = _resolveComponent("image-not-found")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(_ctx.thumbnailCss)
  }, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.state.showLarge,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.showLarge) = $event)),
      centered: "",
      "hide-footer": "",
      "modal-dialog-style": {
                maxWidth: '800px',
            }
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          src: _ctx.encodedImageSrc ?? _ctx.imageUrlFull,
          style: _normalizeStyle({ width: '100%', background: 'transparent url(' + _ctx.spinner + ') center no-repeat' }),
          class: "loading"
        }, null, 12, _hoisted_1)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.encodedImageSrc || _ctx.imageUrlThumb || _ctx.showPlaceholder)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['thumbnail-container', { button: !_ctx.preventOpenModal }]),
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.openFullImage && _ctx.openFullImage(...args)))
        }, [
          (!_ctx.state.hasError && (_ctx.encodedImageSrc || _ctx.imageUrlThumb))
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: _ctx.encodedImageSrc ?? _ctx.imageUrlThumb,
                class: "floortrak-image-thumbnail",
                loading: "lazy",
                onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onImageError && _ctx.onImageError(...args)))
              }, null, 40, _hoisted_2))
            : (_ctx.showPlaceholder || _ctx.state.hasError)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                  _createVNode(_component_image_not_found, {
                    size: _ctx.size === 'lg' ? '50px' : '26px'
                  }, null, 8, ["size"])
                ]))
              : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 4))
}